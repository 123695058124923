import { Component, Vue } from 'vue-property-decorator'
import FormResidency from '@/components/ResidencyForm/Recidency/Residency.vue'
import Residency from '@/Models/Residency'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Item from '@/Models/Item'
import Paginator from '@/components/Paginator/Paginator.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormResidency,
    Search,
    ModalDelete,
    Header,
    Paginator,
    Animation,
  },
})
export default class ViewResidency extends Vue {
  showList = true
  data: Array<Residency> = []
  objResidency: Residency = new Residency()
  //accion que envia dependiendo la opcion
  isAction = 'add'
  loading = false

  url = 'api/residency/arcr_residency/'
  params: Array<Item> = []

  delete_modal_active = false

  perPage = menuModule.perPage_param

  //th de la tabla
  header: Array<any> = [
    {
      field: 'member_number_read',
      label: '# miembro',
    },
    {
      field: 'migration_expedient',
      label: 'Expediente migracion',
    },
    {
      field: 'date_last_movement_read',
      label: 'Ultimo movimiento',
    },
    {
      field: 'status_type_read',
      label: 'Estado',
    },
  ]

  editObj(props: any) {
    this.objResidency = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`api/residency/arcr_residency/${this.objResidency.id}/`)
      this.data = this.data.filter(
        (a: Residency) => a.id !== this.objResidency.id,
      )
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objResidency = props
  }
  closeModal() {
    this.objResidency = new Residency()
    this.delete_modal_active = false
  }
  document(props: any) {
    this.objResidency = props
    const id = this.objResidency.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'ResidencyDocument' })
  }
  Observations(props: any) {
    this.objResidency = props
    const id = this.objResidency.id.toString()
    menuModule.setIdParam(id)
    router.push({ name: 'ResidencyObservation' })
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objResidency = new Residency()
    this.showList = true
    this.isAction = 'add'
  }
  filterItems(items: any) {
    console.log(items, 'list residencia')
    this.data = items.map((obj: any) => ({
      ...obj,
      member_number: obj.member_number != null ? obj.member_number : '',
      member_number_read:
        obj.member_number != null ? obj.member_number.member_number : '',
      date_start_expedient: Helpers.dateParse(obj.date_start_expedient),
      residency_type: obj.residency_type.id,
      date_appointment_lawyers: Helpers.dateParse(obj.date_appointment_lawyers),
      date_documentation: Helpers.dateParse(obj.date_documentation),
      date_appointment_comptroller_services: Helpers.dateParse(
        obj.date_appointment_comptroller_services,
      ),
      date_appeal: Helpers.dateParse(obj.date_appeal),
      date_give_identification: Helpers.dateParse(obj.date_give_identification),
      date_approbation_procedure: Helpers.dateParse(
        obj.date_approbation_procedure,
      ),
      date_change_status: Helpers.dateParse(obj.date_change_status),
      date_last_movement: Helpers.dateParse(obj.date_last_movement),
      date_last_movement_read: obj.date_last_movement,
      status_type_read:
        obj.status_type != null ? obj.status_type.residency_status : '',
      status_type: obj.status_type != null ? obj.status_type.id : '',
      date_residency_renovation: Helpers.dateParse(
        obj.date_residency_renovation,
      ),
    }))
  }

  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
